/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useRoles } from '../../../../../hooks/userRoleContext'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { roles, hasMultiplayerResults } = useRoles(); 

  // Verifica se l'utente ha il ruolo 'admin'
  const isAdmin = roles ? roles.includes('admin') : false;

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* Sessioni VR */}
      <SidebarMenuItemWithSub
        to='#'
        title='Sessioni VR'
        icon='/media/icons/duotune/technology/teh006.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/genera-sessione-vr' title='Genera' hasBullet={true} />
        <SidebarMenuItem to='/storico-sessioni-vr' title='Storico' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Storico risultati */}
      <SidebarMenuItemWithSub
        to='#'
        title='Storico risultati'
        icon='/media/icons/duotune/abstract/abs015.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/storico-risultati-individuale' title='Individuale' hasBullet={true} />
        {hasMultiplayerResults && (
          <SidebarMenuItem to='/storico-risultati-cooperativo' title='Cooperativo' hasBullet={true} />
        )}
      </SidebarMenuItemWithSub>

      {/*Impostazioni*/}
      {isAdmin && (
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Impostazioni</span>
        </div>
      </div>
      )}

      {isAdmin && (
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      )}
    </>
  )
}

export {SidebarMenuMain}
