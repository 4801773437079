import React, { useState, useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getSimulationResults, getSimulationsWithQuizResult } from '../../../../api/homepageApi';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';
import './HomepageWidget.css';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

// Definizione delle interfacce per il tipo di dati ricevuto
interface SimulationResult {
  simulazione: string;
  punteggio: string;
  data_risultato: string;
}

interface Simulation {
  Titolo: string;
}

// Componente Chart con filtro di periodo e simulazione
const ChartsWidget3: React.FC<{ className: string }> = ({ className }) => {
  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [selectedPeriod, setSelectedPeriod] = useState('Year');
  const [selectedSimulation, setSelectedSimulation] = useState<string | null>(null);
  const [results, setResults] = useState<SimulationResult[]>([]);
  const [simulationsResultList, setSimulationsResultList] = useState<string[]>([]);

  const [simulationsList, setSimulationsList] = useState<Simulation[]>([]);

  const refreshChart = () => {
    if (!chartRef.current) return;

    const height = parseInt(getComputedStyle(chartRef.current).height);
    const options = getChartOptions(height, results, selectedPeriod);
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return chart;
  };

  useEffect(() => {
    // Carica la lista delle simulazioni una sola volta all'inizio
    const fetchSimulations = async () => {
      try {
        const simulations = await getSimulationsWithQuizResult(user.COD_User);
        setSimulationsList(simulations);
      } catch (error) {
        console.error('Errore nel recuperare la lista delle simulazioni con quiz fatto:', error);
      }
    };

    fetchSimulations();
  }, []);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await getSimulationResults(user.COD_User, selectedPeriod, selectedSimulation);
        setResults(response);
  
        if (!simulationsList.length) {
          // Ottieni un array di titoli unici
          const simulations = response
            .map((res: SimulationResult) => res.simulazione)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index) // Filtra duplicati
            .map((sim:any) => ({ Titolo: sim })); // Crea oggetti di tipo Simulation
  
          setSimulationsList(simulations);
        }
      } catch (error) {
        console.error('Errore nel recuperare i risultati:', error);
      }
    };
  
    fetchQuizResults();
  }, [selectedPeriod, selectedSimulation]);

  useEffect(() => {
    const chart = refreshChart();
    return () => chart && chart.destroy();
  }, [mode, results]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title'>
          <span className='card-label fw-bold fs-3 mb-1'>Andamento quiz didattica</span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm'
            value={selectedSimulation || ''}
            onChange={(e) => setSelectedSimulation(e.target.value)}
          >
            <option value="">Tutte le simulazioni</option>
            {simulationsList.map((sim, index) => (
              <option key={index} value={sim.Titolo}>
                {sim.Titolo}
              </option>
            ))}
          </select>
          <button
            className={`btn btn-sm btn-outline-secondary ms-2 ${selectedPeriod === 'Year' ? 'active' : ''}`}
            onClick={() => setSelectedPeriod('Year')}
          >
            Anno
          </button>
          <button
            className={`btn btn-sm btn-outline-secondary ms-2 ${selectedPeriod === 'Month' ? 'active' : ''}`}
            onClick={() => setSelectedPeriod('Month')}
          >
            Mese
          </button>
          <button
            className={`btn btn-sm btn-outline-secondary ms-2 ${selectedPeriod === 'Week' ? 'active' : ''}`}
            onClick={() => setSelectedPeriod('Week')}
          >
            Settimana
          </button>
        </div>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  );
};

function getChartOptions(height: number, data: SimulationResult[], selectedPeriod: string): ApexOptions {
  let categories: string[] = [];
  
  if (selectedPeriod === 'Year') {
    // Ultimi 12 mesi
    categories = Array.from({ length: 12 }, (_, i) => 
      dayjs().subtract(i, 'month').format('MMMM')
    ).reverse();
  } else if (selectedPeriod === 'Month') {
    // Ultimi 30 giorni
    categories = Array.from({ length: 30 }, (_, i) => 
      dayjs().subtract(i, 'day').format('DD')
    ).reverse();
  } else if (selectedPeriod === 'Week') {
    // Giorni della settimana precedente
    categories = Array.from({ length: 7 }, (_, i) => 
      dayjs().subtract(i, 'day').format('dddd')
    ).reverse();
  }

  const seriesData = categories.map((category) => {
    // Filtra i dati per ogni categoria temporale
    const resultsForCategory = data.filter((result) => {
      const resultDate = dayjs(result.data_risultato);
      if (selectedPeriod === 'Year') {
        return resultDate.format('MMMM') === category;
      } else if (selectedPeriod === 'Month') {
        return resultDate.format('DD') === category;
      } else if (selectedPeriod === 'Week') {
        return resultDate.format('dddd') === category;
      }
      return false;
    });

    // Calcola la media dei punteggi per quella categoria
    const scores = resultsForCategory.map((res) => {
      const [correct, total] = res.punteggio.split('/').map(Number);
      return (correct / total) * 100;
    });

    return scores.length > 0 ? scores.reduce((a, b) => a + b, 0) / scores.length : 0;
  });

  return {
    series: [{ name: 'Punteggio (%)', data: seriesData }],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height,
      toolbar: { show: false },
    },
    xaxis: {
      categories,
      labels: { style: { fontSize: '12px' } },
      title: {
        text: 'Periodo (Mesi/Giorni)', // Descrizione accanto all'asse X
        style: { fontSize: '14px', fontWeight: 'bold', color: '#333' },
      },
    },
    yaxis: {
      labels: { formatter: (val) => `${val.toFixed(0)}%` },
      title: {
        text: 'Punteggio (%)', // Descrizione accanto all'asse Y
        rotate: -90, // Mantiene il testo in verticale
        offsetX: -5, // Distanza dal grafico (usa un valore negativo per spostarlo più lontano)
        style: { fontSize: '14px', fontWeight: 'bold', color: '#333' },
      },
    },    
    tooltip: {
      enabled: false, // Disattiva completamente il tooltip
    },    
    colors: ['#0d6efd'],
    stroke: { curve: 'smooth' },
    markers: { size: 5 },
    grid: { padding: { left: 10, right: 10 } },
  };
}

// Inserimento di ChartsWidget3 nel componente principale HomepageWidget
const HomepageWidget: React.FC = () => {
  return (
    <div className="container-fluid" style={{ padding: '0px' }}>
      <div className="row">
        <div className="col-xl-6 col-lg-8">
          <ChartsWidget3 className="h-100" />
        </div>
      </div>
    </div>
  );
};

export { HomepageWidget };