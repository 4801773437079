import axios from '../config/axiosConfig';


/* Elenco dei risultati ottenuti nelle didattiche delle varie simulazioni */
export const getSimulationResults = async (idUser, selectedPeriod, selectedSimulation) => {
  try {
    const response = await axios.post('/api/homepage/listQuizResults', { idUser, selectedPeriod, selectedSimulation });
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz results: ', error);
    throw error;
  }
};

 // Lista delle simulazioni delle quali si è fatto almeno una volta la didattica
 export const getSimulationsWithQuizResult = async (idUser) => {
  try {
    console.log("fin qas")
    const response = await axios.get(`/api/homepage/listSimulationsWithQuizResult/${idUser}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recuperare la lista delle simulazioni con quiz fatto:', error);
    throw error;
  }
};
