/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {
  HomepageWidget,
} from '../../../_metronic/partials/widgets/customs/HomepageWidget'

const DashboardPage: FC = () => (
  <>
    <HomepageWidget />
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
